import {
  Box,
  // Button,
  Checkbox,
  // Chip,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { SnackbarKey, enqueueSnackbar, useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { removeAsinsAPI, useSpaceQuery } from "./useSpaces.ts";
import { useSpace } from "./SpaceProvider.tsx";
import theme from "../../themes/light.ts";
import {
  AddSpaceV2NoImage,
  CopyIcon,
  // LightStarIcon,
  // ProductHoverBackground,
} from "../../assets/index.tsx";
import React, { ReactNode } from "react";
import { useStyles } from "./useStyles.tsx";
// import CropOriginalIcon from "@mui/icons-material/CropOriginal";

// const ProductPlaceholder = ({ asin }: { asin: string }) => {
//   return (
//     <div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "70px",
//         }}
//       >
//         <CropOriginalIcon style={{ color: "#8E4FF6", fontSize: "54px" }} />
//       </div>
//       <Typography
//         style={{
//           textAlign: "center",
//           fontWeight: "bold",
//           color: "#8E4FF6", // Change this to your desired color
//         }}
//       >
//         {asin}
//       </Typography>
//     </div>
//   );
// };

const SpaceProductItem = ({
  asin,
  spaceId,
  undoAction,
}: {
  asin: any;
  spaceId: string;
  undoAction: (snackbarId: SnackbarKey, asins: string[]) => ReactNode;
}) => {
  const { refatchAsins, openKeywordsTable } = useSpace();
  const { refetch: spaceDataRefetch } = useSpaceQuery(spaceId);
  const snackbar = useSnackbar();
  const { selectedProducts, toggleProductSelection } = useSpace();

  const classes = useStyles();

  const brand = asin?.brand?.toLowerCase();
  const displayTitle =
    brand && asin?.title?.toLowerCase().startsWith(brand)
      ? asin?.title?.substring(brand.length)
      : " " + asin?.title;

  const removeASINsMutation = useMutation(
    ({ spaceId, asins }: { spaceId: string; asins: string[] }) =>
      removeAsinsAPI({
        spaceId,
        asins,
      })
  );

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(asin.asin).then(() =>
      enqueueSnackbar("Asin copied successfully", {
        variant: "success",
      })
    );
  };

  return (
    <Grid
      item
      xs={12}
      sm={4}
      md={openKeywordsTable ? 6 : 3}
      lg={openKeywordsTable ? 4 : 2}
      xl={openKeywordsTable ? 3 : 12 / 7}
    >
      <Box
        className={asin.title ? "" : classes.shimmer}
        onClick={() => {
          toggleProductSelection(asin.asin, "toggle");
        }}
        sx={{
          width: "100%",
          height: "191px",
          padding: "14px 0",
          boxShadow:
            "0px 6px 15px 0px rgba(44, 34, 76, 0.05), 0px 8px 32px 0px rgba(44, 34, 76, 0.05)",
          background: selectedProducts?.includes(asin.asin)
            ? theme.palette.primary.light
            : theme.palette.background.default,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "space-between",
          borderRadius: "12px",
          cursor: "pointer",
          position: "relative",
          overflow: "hidden",
          "#secondary": {
            position: "absolute",
            bottom: "0",
            height: "90px",
            left: 0,
            display: "flex",
            opacity: 0,
            background: "white",
            transition: "opacity 0.15s ease-out allow-discrete",
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            borderRadius: "0px 0px 10px 10px",
            boxShadow: "0px -4px 6px 0px rgba(0, 0, 0, 0.09)",
            marginLeft: "2px",
            padding: "8.5px 12px 0 12px",
          },
          "&:hover *": {
            visibility: "visible",
          },
          "&:hover #secondary": {
            opacity: 1,
            bottom: 0,
            display: "flex",
          },
          ":hover": {
            "& .MuiCheckbox-root": {
              display: "inline-flex",
            },
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: "inherit",
              padding: "2px",
              background:
                "linear-gradient(to bottom, rgba(142, 79, 246, 1) 0%, rgba(142, 79, 246, 0) 100%)",
              WebkitMask:
                "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              WebkitMaskComposite: "xor",
              maskComposite: "exclude",
            },
          },
          ":hover svg": {
            opacity: "1 !important",
          },
        }}
      >
        <Checkbox
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            mx: 0,
            display: selectedProducts?.includes(asin.asin)
              ? "inline-flex"
              : "none",
          }}
          checked={
            selectedProducts?.includes(asin.asin) !== undefined
              ? selectedProducts?.includes(asin.asin)
              : false
          }
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {asin?.image_url ? (
            <Box
              component={"img"}
              src={asin?.image_url}
              sx={{
                height: "127px",
                width: "100%",
                padding: "0 5px 0 10px",
                maxHeight: "127px",
                objectFit: "contain",
              }}
            />
          ) : (
            <Box
              sx={{
                height: "127px",
                width: "100%",
                padding: "0 5px 0 10px",
                maxHeight: "127px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AddSpaceV2NoImage />
            </Box>
          )}
          <Stack
            sx={{
              display: "flex",
              width: "100%",
              marginLeft: "16px",
              marginTop: "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: 600,
              }}
              className={classes.truncateMultiLine}
            >
              <span
                className={classes.brand}
                style={{
                  display: "inline-flex",
                  height: "16px",
                  padding: "4px",
                }}
              >
                {asin.brand}
              </span>
            </Typography>
          </Stack>
        </Box>

        {/* <Stack
          id="main"
          height="100%"
          justifyContent="center"
          position="relative"
        >
          {asin?.image_url ? (
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <Box
                component={"img"}
                src={asin?.image_url}
                sx={{
                  height: "100%",
                  maxHeight: "135px",
                  objectFit: "contain",
                }}
              />
              <Typography
                fontWeight="bold"
                fontSize={12}
                className={classes.truncateMultiLine}
                marginTop="10px"
                sx={{
                  width: "100%",
                  padding: "0 1rem",
                  position: "absolute",
                  bottom: "20px",
                  left: 0,
                }}
              >
                <span
                  className={classes.brand}
                  style={{ display: "inline-flex" }}
                >
                  {asin.label === "my product" && (
                    <Box
                      component="span"
                      sx={{
                        position: "relative",
                        top: "2px",
                        marginRight: "4px",
                      }}
                    >
                      <LightStarIcon />
                    </Box>
                  )}
                  <Box>{asin.brand}</Box>
                </span>
              </Typography>
            </Box>
          ) : (
            <ProductPlaceholder asin={asin.asin} />
          )}
        </Stack>
        <Box className={classes.productHoverBG}>
          <ProductHoverBackground />
        </Box> */}
        <Stack id="secondary" justifyContent="space-between">
          <Tooltip
            placement="top"
            title={asin.title}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -10], // Change the second value to adjust the vertical distance
                  },
                },
              ],
            }}
          >
            {asin.title && (
              <Box>
                <Typography
                  fontWeight="bold"
                  fontSize={12}
                  className={classes.truncateMultiLine}
                >
                  <span className={classes.brand}>{asin.brand}</span>
                  <span style={{ display: "inline" }}>{displayTitle}</span>
                </Typography>
              </Box>
            )}
          </Tooltip>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              position: "absolute",
              left: "0",
              bottom: "4px",
              padding: "0 0.5rem 0 1rem",
            }}
          >
            <Link
              underline={"hover"}
              target={"_blank"}
              href={asin.asin ? `https://amazon.com/d/${asin.asin}` : ""}
              sx={{
                color: theme.palette.secondary.main,
                fontSize: "10px",
                fontWeight: 600,
                lineHeight: "17.59px",
                cursor: "pointer",
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            >
              {asin?.asin}
            </Link>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Tooltip
                placement="bottom"
                title="Copy ASIN to clipboard"
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                }}
              >
                <IconButton
                  onClick={handleCopy}
                  sx={{
                    background: "unset",
                    padding: "7px",
                    "& svg": {
                      height: "15px",
                      width: "15px",
                      fill: theme.palette.secondary.main,
                    },
                  }}
                >
                  <CopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                placement="bottom"
                title="Remove product from space"
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                }}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    removeASINsMutation
                      .mutateAsync({
                        spaceId: spaceId as string,
                        asins: [asin.asin],
                      })
                      .then(async () => {
                        snackbar.enqueueSnackbar("ASIN removed", {
                          variant: "success",
                          action: (snackbarId) =>
                            undoAction(snackbarId, [asin.asin]),
                        });
                        spaceDataRefetch();
                        await refatchAsins();
                        toggleProductSelection(asin.asin);
                      })
                      .catch((e) => {
                        snackbar.enqueueSnackbar("Something went wrong: " + e, {
                          variant: "error",
                        });
                      });
                  }}
                  sx={{
                    background: "unset",
                    padding: "4px",
                  }}
                >
                  <DeleteOutlinedIcon
                    sx={{
                      fontSize: "20px",
                      fill: theme.palette.secondary.main,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
};

export default SpaceProductItem;
