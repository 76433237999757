import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Features } from "./types.ts";

const AddAttributeForm = ({
  add,
  setExpanded,
}: {
  add: (attribute: Features) => void;
  setExpanded: (expanded: boolean) => void;
}) => {
  const { watch, setValue, register, handleSubmit } = useForm({
    defaultValues: {
      attribute: "",
      description: "",
      accepted_values: [] as (string | number)[],
      closed_list: false,
      type: "string",
    },
  });
  const typeValue = watch(`type`);

  const parseAcceptedValues = (values: string) => {
    switch (typeValue) {
      case "int":
        return values.split(",").map((value) => parseInt(value.trim()));
      case "float":
        return values.split(",").map((value) => parseFloat(value.trim()));
      default:
        return values.split(",").map((value) => value.trim());
    }
  };

  const validateAcceptedValues = () => {
    if (watch("closed_list")) {
      const acceptedValues = watch("accepted_values")
        .toLocaleString()
        .split(",");
      switch (typeValue) {
        case "int":
          return acceptedValues.every((value) => !isNaN(parseInt(value)));
        case "float":
          return acceptedValues.every((value) => !isNaN(parseFloat(value)));
        default:
          return true;
      }
    }
    return true;
  };

  return (
    <Stack spacing={1}>
      <TextField
        placeholder="Attribute"
        {...register(`attribute`, { required: true })}
      />
      <TextField
        placeholder="Description"
        multiline
        {...register(`description`, { required: true })}
        rows={3}
      />
      <TextField
        placeholder="Accepted values (separated by comma)"
        multiline
        rows={3}
        disabled={!watch("closed_list")}
        onChange={(e) => {
          setValue("accepted_values", parseAcceptedValues(e.target.value));
        }}
      />
      <FormControlLabel
        control={
          <Switch
            {...register(`closed_list`)}
            disabled={watch(`type`) === "boolean"}
          />
        }
        label="Closed list"
      />
      <ToggleButtonGroup
        onChange={(_e: React.MouseEvent<HTMLElement>, value: string) => {
          setValue("type", value);
          if (value === "boolean") {
            setValue("accepted_values", []);
          }
        }}
        exclusive
      >
        <ToggleButton value="string" selected={typeValue === "string"}>
          String
        </ToggleButton>
        <ToggleButton
          value="boolean"
          selected={typeValue === "boolean"}
          disabled={watch(`closed_list`)}
        >
          Boolean
        </ToggleButton>
        <ToggleButton value="int" selected={typeValue === "int"}>
          Integer
        </ToggleButton>
        <ToggleButton value="float" selected={typeValue === "float"}>
          Float
        </ToggleButton>
      </ToggleButtonGroup>
      <Button
        variant="contained"
        type="button"
        disabled={
          !watch(`attribute`) ||
          !watch(`description`) ||
          !validateAcceptedValues()
        }
        onClick={handleSubmit((data) => {
          setExpanded(false);
          add(data as Features);
        })}
      >
        Add feature
      </Button>
    </Stack>
  );
};

export default AddAttributeForm;
