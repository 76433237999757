import { FC, useEffect, useState } from "react";
import { Typography } from "@mui/material";

const FadedText: FC<{ text: string; open: boolean }> = ({ text, open }) => {
  const DEFAULT_HEIGHT = "30px"
  const [height, setHeight] = useState(open ? DEFAULT_HEIGHT : 0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    if (open) {
      setHeight(DEFAULT_HEIGHT);
      setTimeout(() => setIsFading(true), 0);
    } else {
      setIsFading(false);
      setTimeout(() => setHeight(0), 500);
    }
  }, [open]);

  return (
    <Typography
      color="#FFF"
      fontWeight={400}
      fontSize="12px"
      fontStyle="normal"
      sx={{
        minWidth: "200px",
        height: height,
        opacity: isFading ? 1 : 0,
        transition: "opacity 0.5s ease, height 0.5s ease",
        "-webkit-font-smoothing": "subpixel-antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        "text-shadow": "0.03px 0.03px 0 rgba(0, 0, 0, 0.1)",

      }}
    >
      {text}
    </Typography>
  )
};

export default FadedText;
