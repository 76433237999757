import {
  addAsinsAPI,
  moveSuggestedToSpaceAPI,
  rejectSuggestedAPI,
  removeAsinsAPI,
  updateSpaceTitleApi,
  useSpaceQuery,
} from "./useSpaces.ts";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  debounce,
  Drawer,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import theme from "../../themes/light.ts";
// import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Space, SpaceASINsResponse } from "./types.ts";
import { useEffect, useMemo, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ExtractFeaturesDrawer from "./FeatureExtraction/ExtractFeaturesDrawer.tsx";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation } from "react-query";
import { closeSnackbar, SnackbarKey, useSnackbar } from "notistack";
import { useSpace } from "./SpaceProvider.tsx";
import AddManualSpaceDrawer from "./AddManualSpaceDrawer.tsx";
import AsinsList from "./AsinsList.tsx";
import { DarkStarIcon, DropDownIcon, TrendsIcon } from "../../assets/index.tsx";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import AsinsListTableView from "./AsinsListTableView.tsx";
import useQueryParams from "../../hooks/useQueryParams.ts";
import { SPACES_ROUTE } from "../../pages/Applications/Router";
import KeywordsTable from "./KeywordsTable.tsx";
import useSplitEnabled from "../../hooks/useSplitEnabled.ts";
import { Split } from "../../FeatureFlags/enums.ts";
import SVG from "react-inlinesvg";
import { useAppContext } from "../../ApplicationContext.tsx";
import { NavbarModes } from "../../enums.ts";
import { GridApi } from "ag-grid-community";
import apiRequest from "../../services/apiRequest.ts";
import { AgGridReact } from "ag-grid-react";
import { PerfectContentIcon } from "../Applications/LeftNavbar/PerfectContentList.tsx";
import CategoryIcon from "@mui/icons-material/Category";
import AsinsListPerfectContentView from "./PerfectContent/AsinsListPerfectContentView.tsx";
import AsinsListFeatureExtractionView from "./FeatureExtraction/AsinsListFeatureExtractionView.tsx";

const SpaceDetail = () => {
  const {
    setFilterTextAfterDebounce,
    setFilterByLabel,
    filterByLabel,
    contains,
    openKeywordsTable,
    setOpenKeywordsTable,
    setContains,
    filterTextAfterDebounce,
    asinsList,
    view,
    setView,
  } = useSpace();
  const snackbar = useSnackbar();
  const { navbarMode } = useAppContext();
  const history = useHistory();
  const queryParams = useQueryParams();
  const tabId = queryParams.get("tabId") || "";
  const [editTitleMode, setEditTitleMode] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [asinsFromGrid, setAsinsFromGrid] = useState<string[]>([]);
  // const [view, setView] = useState<string>("grid");
  const gridRef = useRef<AgGridReact<any>>(null);
  const [editTitleValue, setEditTitleValue] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isAddAsinsDrawerOpen, setIsAddAsinsDrawerOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filterText, setFilterText] = useState("");
  const { spaceId } = useParams<{ spaceId?: string }>();
  const isPerfectContentV2Enabled = useSplitEnabled(Split.PERFECT_CONTENT_V3);

  const { data: data, refetch: spaceDataRefetch } = useSpaceQuery(spaceId);
  const {
    // spaceData: data,
    spacesListRefetch,
    selectedProducts,
    refatchAsins,
    allSpaceAsins,
    // spaceDataRefetch,
    setSelectedProducts,
  } = useSpace();

  const addAsinsMutation = useMutation(
    ({ spaceId, asins }: { spaceId: string; asins: string[] }) =>
      addAsinsAPI({ spaceId, asins }),
  );

  useEffect(() => {
    setFilterByLabel("all_product");
  }, []);

  useEffect(() => {
    if (tabId) setFilterByLabel(tabId);
    history.push(`${SPACES_ROUTE}/${spaceId}`);
  }, [tabId]);

  function convertToNumber(string: string): number {
    const suffixes: { [key: string]: number } = {
      K: 1e3,
      M: 1e6,
      B: 1e9,
      T: 1e12,
    };

    // Remove any commas for numbers like "1,000K"
    const sanitizedString = string.replace(/,/g, "");

    const regex = /^([\d.]+)([KMBT])?$/i;
    const match = sanitizedString.match(regex);

    if (match) {
      const value = parseFloat(match[1]);
      const suffix = match[2] ? match[2].toUpperCase() : "";

      if (suffix in suffixes) {
        return value * suffixes[suffix];
      }
      return value;
    }

    throw new Error("Invalid number format");
  }

  const convertNumberToShortFormat = (value: string | number) => {
    const number = Number(value);
    const thresholds = [
      { value: 1e9, symbol: "B" },
      { value: 1e6, symbol: "M" },
      { value: 1e3, symbol: "K" },
    ];

    const threshold = thresholds.find((threshold) => number >= threshold.value);

    if (threshold) {
      return (
        (number / threshold.value).toFixed(1).replace(/\.0$/, "") +
        threshold.symbol
      );
    }

    return number.toString();
  };

  // const handleSorting = (allOfTheData: any, sortModel: any) => {
  //   return sortData(sortModel, allOfTheData);
  // };

  // const sortData = (sortModel: any, data: any) => {
  //   const sortPresent = sortModel && sortModel.length > 0;
  //   if (!sortPresent) {
  //     return data;
  //   }
  //   // do an in memory sort of the data, across all the fields
  //   if (data && data?.length > 0) {
  //     const resultOfSort = data.slice();
  //     resultOfSort.sort(function (a: any, b: any) {
  //       for (let k = 0; k < sortModel.length; k++) {
  //         const sortColModel = sortModel[k];
  //         const valueA = a[sortColModel.colId];
  //         const valueB = b[sortColModel.colId];
  //         // this filter didn't find a difference, move onto the next one
  //         if (valueA == valueB) {
  //           continue;
  //         }
  //         const sortDirection = sortColModel.sort === "asc" ? 1 : -1;
  //         if (valueA > valueB) {
  //           return sortDirection;
  //         } else {
  //           return sortDirection * -1;
  //         }
  //       }
  //       // no filters found a difference
  //       return 0;
  //     });
  //     return resultOfSort;
  //   }
  // };

  const createDataSource = () => ({
    rowCount: undefined,
    getRows: (params: any) => {
      const { successCallback, failCallback } = params;
      const queryParams: any = {};
      const limit = 100;

      if (filterByLabel) {
        queryParams.product_label_filter = filterByLabel;
      }
      if (limit) {
        queryParams.limit = limit;
      }
      if (params.startRow) {
        queryParams.offset = params.startRow;
      }
      if (filterTextAfterDebounce) {
        queryParams.text_filter = filterTextAfterDebounce;
        queryParams.not_contain = !contains;
      }
      if (params.sortModel && params.sortModel.length) {
        queryParams.sort_by = params.sortModel.map((sortObj: any) =>
          sortObj.sort === "desc" ? `-${sortObj.colId}` : sortObj.colId,
        );
      }

      if (spaceId) {
        apiRequest<SpaceASINsResponse>(
          `v1/spaces/${spaceId}/asins`,
          "GET",
          queryParams,
        )
          .then((res) => {
            const asins = res?.asins;
            const parsedData = asins?.map((asin) => {
              const formattedBoughtPastMonth = asin?.bought_past_month
                ? asin?.bought_past_month?.split("+")[0]
                : "";
              return {
                ...asin,
                price: Number(asin?.price),
                reviews: Number(asin?.num_of_reviews),
                title: asin?.title
                  ? asin?.title?.charAt(0)?.toUpperCase() +
                  asin?.title?.slice(1)?.toLowerCase()
                  : "",
                brand: asin?.brand
                  ? asin?.brand?.charAt(0)?.toUpperCase() +
                  asin?.brand?.slice(1)?.toLowerCase()
                  : "",
                bought_past_month: formattedBoughtPastMonth
                  ? convertToNumber(formattedBoughtPastMonth)
                  : "",
              };
            });

            // let sortingModel = [];
            // if (params?.sortModel && params?.sortModel?.length) {
            //   sortingModel = [...params?.sortModel];
            // } else {
            //   sortingModel?.push({
            //     sort: "desc",
            //     colId: "bought_past_month",
            //   });
            // }

            // const dataAfterSorting = handleSorting(parsedData, sortingModel);
            // const rowData = dataAfterSorting?.map((asin: any) => {
            //   return {
            //     ...asin,
            //     product: { image_url: asin.image_url, asin: asin.asin },
            //     bought_past_month: asin.bought_past_month
            //       ? convertNumberToShortFormat(asin.bought_past_month) + "+"
            //       : "",
            //   };
            // });

            const rowData = parsedData?.map((asin: any) => {
              return {
                ...asin,
                product: { image_url: asin.image_url, asin: asin.asin },
                bought_past_month: asin.bought_past_month
                  ? convertNumberToShortFormat(asin.bought_past_month) + "+"
                  : "",
              };
            });

            let lastRow = -1;
            if (res?.asins && res?.asins?.length <= params.endRow) {
              lastRow = res?.total;
            }
            successCallback(rowData, lastRow);
          })
          .catch((error) => {
            console.error(error);
            failCallback();
          });
      } else {
        Promise.resolve([]);
      }
    },
  });

  const fetchAsinsForGrid = () => {
    const datasource = createDataSource();
    gridApi?.setGridOption("datasource", datasource);
  };

  useEffect(() => {
    if (view === "table") {
      fetchAsinsForGrid();
    }
    if (filterTextAfterDebounce) {
      spaceDataRefetch();
    }
  }, [filterTextAfterDebounce]);

  useEffect(() => {
    if (filterTextAfterDebounce) {
      if (view === "table") {
        fetchAsinsForGrid();
      }
      spaceDataRefetch();
    }
  }, [contains]);

  const debouncedOnChange = debounce(setFilterTextAfterDebounce, 500);

  // const keywordsEnabled = useSplitEnabled(Split.SPACE_KEYWORDS);
  const featureExtractionEnabled = useSplitEnabled(Split.FEATURE_EXTRACTION);

  const updateTitleMutation = useMutation(
    ({ spaceId, newName }: { spaceId: string; newName: string }) =>
      updateSpaceTitleApi({
        spaceId,
        newName,
      }),
  );

  // const asins = useMemo(() => {
  //   let asins: any[] = [];
  //   asinsList?.pages.forEach((page: any) => {
  //     asins = asins.concat(page.asins);
  //   });
  //   if (sort?.column === "bought_past_month") {
  //     asins?.sort((a, b) => {
  //       const bought1 = a?.bought_past_month
  //         ? a?.bought_past_month?.split("+")[0]
  //         : "";
  //       const formattedV1: any = bought1 ? convertToNumber(bought1) : "";
  //       const bought2 = b?.bought_past_month
  //         ? b?.bought_past_month?.split("+")[0]
  //         : "";
  //       const formattedV2: any = bought2 ? convertToNumber(bought2) : "";
  //       return sort?.ascending
  //         ? formattedV1 - formattedV2
  //         : formattedV2 - formattedV1;
  //     });
  //   } else {
  //     asins.sort((a, b) => {
  //       const time1 = new Date(a.created_at).getTime();
  //       const time2 = new Date(b.created_at).getTime();
  //       return sort?.ascending ? time1 - time2 : time2 - time1;
  //     });
  //   }
  //   return asins;
  // }, [asinsList?.pages, sort?.ascending, sort?.column]);

  const asins = useMemo(() => {
    let asins: any[] = [];
    asinsList?.pages.forEach((page: any) => {
      asins = asins.concat(page.asins);
    });
    return asins;
  }, [asinsList?.pages]);

  useEffect(() => {
    setEditTitleValue((data as Space)?.name);
  }, [data]);

  useEffect(() => {
    debouncedOnChange(filterText);
  }, [filterText]);

  const removeASINsMutation = useMutation(
    ({ spaceId, asins }: { spaceId: string; asins: string[] }) =>
      removeAsinsAPI({
        spaceId,
        asins,
      }),
  );

  const rejectSuggestedMutation = useMutation(
    ({ spaceId, asins }: { spaceId: string; asins: string[] }) =>
      rejectSuggestedAPI({
        spaceId,
        asins,
      }),
  );
  const moveSuggestedToSpaceMutation = useMutation(
    ({ spaceId, asins }: { spaceId: string; asins: string[] }) =>
      moveSuggestedToSpaceAPI({
        spaceId,
        asins,
      }),
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value: boolean) => {
    setContains(value);
    setAnchorEl(null);
  };

  const handleBack = () => {
    history.push(SPACES_ROUTE);
    setSelectedProducts([]);
    setOpenKeywordsTable(false);
  };

  const totalAsins = useMemo(() => {
    if (view === "table") {
      return asinsFromGrid?.length;
    }
    const asinLength = asinsList?.pages?.reduce((acc: number, curr: any) => {
      return acc + curr?.total;
    }, 0);
    return asinLength;
  }, [asinsList?.pages, asinsFromGrid]);

  const getProductCountLabel = () => {
    if (selectedProducts && selectedProducts?.length > 0) {
      if (selectedProducts?.length === allSpaceAsins?.length) {
        return `${allSpaceAsins?.length} selected`;
      }
      let allAsins: string[] = [];
      if (view === "table" && gridApi) {
        allAsins = asinsFromGrid;
      } else {
        allAsins = asins?.map((ele) => ele?.asin);
      }
      if (allAsins?.length > 0) {
        const commonProducts =
          selectedProducts?.filter((el) => allAsins?.includes(el)) || [];
        return `${commonProducts?.length} selected`;
      }
    }
    if (filterTextAfterDebounce) {
      switch (filterByLabel) {
        case "all_product":
          return `${totalAsins} products (filtered)`;
        case "my_product":
          return `${totalAsins} products (filtered)`;
        case "competitive_product":
          return `${totalAsins} products (filtered)`;
        // case "fetching_data":
        //   return data?.metadata?.asins?.fetching_data;
        case "suggested_asins":
          return `${totalAsins} products (filtered)`;

        default:
          return `${data?.metadata?.asins?.total}`;
      }
    } else {
      switch (filterByLabel) {
        case "all_product":
          return `${data?.metadata?.asins?.total} products`;
        case "my_product":
          return `${data?.metadata?.asins?.my_products} products`;
        case "competitive_product":
          return `${data?.metadata?.asins?.competitive_products} products`;
        // case "fetching_data":
        //   return data?.metadata?.asins?.fetching_data;
        case "suggested_asins":
          return `${data?.metadata?.suggested_asins?.total} products`;

        default:
          return `${data?.metadata?.asins?.total} products`;
      }
    }
  };

  const productCountLabel = useMemo(
    () => getProductCountLabel(),
    [
      filterByLabel,
      selectedProducts,
      data,
      asinsList,
      asinsFromGrid,
      allSpaceAsins,
    ],
  );

  const handleChangeLayout = (
    _: React.MouseEvent<HTMLElement>,
    nextView: string,
  ) => {
    if (nextView) {
      setView(nextView);
    }
  };

  const handleKeywordsTable = () => {
    setOpenKeywordsTable(!openKeywordsTable);
  };

  const handleUndoAsins = (snackbarId: SnackbarKey, asins: string[]) => {
    addAsinsMutation
      .mutateAsync({ spaceId: spaceId as string, asins })
      .then(async () => {
        snackbar.enqueueSnackbar(
          asins?.length === 1
            ? "ASIN was added successfully"
            : "ASINs were added successfully",
          {
            variant: "success",
          },
        );
        spaceDataRefetch();
        if (view === "table") {
          await fetchAsinsForGrid();
          if (gridApi) {
            gridApi.deselectAll();
          }
        } else {
          await refatchAsins();
        }
        closeSnackbar(snackbarId);
      })
      .catch((e) => {
        snackbar.enqueueSnackbar("Something went wrong: " + e, {
          variant: "error",
        });
      });
  };

  const undoAction = (snackbarId: SnackbarKey, asins: string[]) => (
    <>
      <Button
        sx={{
          color: theme.palette.primary.contrastText,
          padding: "5px 10px",
          borderRadius: "4px",
          background: theme.palette.secondary.main,
          ":hover": {
            background: theme.palette.secondary.main,
          },
        }}
        onClick={() => handleUndoAsins(snackbarId, asins)}
      >
        Undo
      </Button>
    </>
  );

  const onModelUpdated = () => {
    if (gridRef && gridRef?.current?.api) {
      const allAsins: string[] = [];
      gridRef?.current!.api.forEachNode((node) => {
        allAsins?.push(node?.data?.asin);
        if (
          selectedProducts?.some(
            (selectedAsin) => node?.data?.asin === selectedAsin,
          )
        ) {
          node.setSelected(true);
        }
      });
      setAsinsFromGrid(allAsins);
    }
  };

  return (
    <>
      <Stack height={"100vh"}>
        <Stack px={4} py={2} bgcolor={"white"} height="168px">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="column">
              <Box>
                <Button
                  id="composition-button"
                  aria-haspopup="true"
                  // disabled={isDisabled || false}
                  onClick={handleBack}
                  sx={{
                    display: "flex",
                    gap: "8px",
                    minHeight: "28px",
                    fontSize: "14px",
                    color: "#2C224C",
                  }}
                >
                  <ArrowBackIcon
                    sx={{
                      color: theme.palette.grey[300],
                    }}
                  />
                  <Typography
                    variant="body4"
                    sx={{
                      fontSize: "14px",
                      color: theme.palette.grey[400],
                    }}
                  >
                    Go back
                  </Typography>
                </Button>
              </Box>
              {!editTitleMode && (
                <Stack
                  mb="0.5rem"
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  sx={{
                    "& button": {
                      visibility: "hidden",
                    },
                    "&:hover button": {
                      visibility: "visible",
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={theme.palette.secondary.main}
                    fontSize={20}
                  >
                    {editTitleValue}
                  </Typography>
                  <IconButton onClick={() => setEditTitleMode(true)}>
                    <EditIcon
                      sx={{
                        fontSize: "14px",
                      }}
                    />
                  </IconButton>
                </Stack>
              )}
              {editTitleMode && (
                <Stack
                  mb="0.8rem"
                  spacing={2}
                  direction="row"
                  alignItems="center"
                >
                  <TextField
                    value={editTitleValue}
                    onInput={(e) =>
                      setEditTitleValue((e.target as HTMLInputElement).value)
                    }
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      updateTitleMutation
                        .mutateAsync({
                          spaceId: spaceId as string,
                          newName: editTitleValue,
                        })
                        .then(() => {
                          setEditTitleMode(false);
                          spaceDataRefetch();
                          refatchAsins();
                          spacesListRefetch();
                          snackbar.enqueueSnackbar("space name updated!", {
                            variant: "success",
                          });
                        })
                        .catch((e) => {
                          snackbar.enqueueSnackbar(
                            "Something went wrong: " + e,
                            {
                              variant: "error",
                            },
                          );
                        });
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setEditTitleValue((data as Space)?.name);
                      setEditTitleMode(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              )}
            </Stack>
            {selectedProducts && selectedProducts?.length > 0 ? (
              <Stack gap={2} direction="row">
                {filterByLabel === "suggested_asins" ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      moveSuggestedToSpaceMutation
                        .mutateAsync({
                          spaceId: spaceId!,
                          asins: selectedProducts,
                        })
                        .then(async () => {
                          snackbar.enqueueSnackbar(
                            selectedProducts?.length + " ASINs moved to space",
                            {
                              variant: "success",
                            },
                          );
                          spaceDataRefetch();
                          await refatchAsins();
                          setSelectedProducts([]);
                        })
                        .catch((e) => {
                          snackbar.enqueueSnackbar(
                            "Something went wrong: " + e,
                            {
                              variant: "error",
                            },
                          );
                        });
                    }}
                  >
                    Add to space
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      if (view === "table" && gridApi) {
                        gridApi?.deselectAll();
                      }
                      setSelectedProducts([]);
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {filterByLabel === "suggested_asins" ? (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      rejectSuggestedMutation
                        .mutateAsync({
                          spaceId: spaceId!,
                          asins: selectedProducts,
                        })
                        .then(async () => {
                          snackbar.enqueueSnackbar(
                            selectedProducts?.length +
                            " ASINs removed from suggestion",
                            {
                              variant: "success",
                            },
                          );
                          spaceDataRefetch();
                          if (view === "table") {
                            await fetchAsinsForGrid();
                          } else {
                            await refatchAsins();
                          }
                          setSelectedProducts([]);
                        })
                        .catch((e) => {
                          snackbar.enqueueSnackbar(
                            "Something went wrong: " + e,
                            {
                              variant: "error",
                            },
                          );
                        });
                    }}
                  >
                    Remove from space
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      removeASINsMutation
                        .mutateAsync({
                          spaceId: spaceId!,
                          asins: selectedProducts,
                        })
                        .then(async () => {
                          snackbar.enqueueSnackbar(
                            `${selectedProducts?.length} ${selectedProducts?.length === 1 ? "ASIN" : "ASINs"
                            } removed`,
                            {
                              variant: "success",
                              action: (snackbarId) =>
                                undoAction(snackbarId, selectedProducts),
                            },
                          );
                          spaceDataRefetch();
                          if (view === "table") {
                            await fetchAsinsForGrid();
                          } else {
                            await refatchAsins();
                          }
                          setSelectedProducts([]);
                        })
                        .catch((e) => {
                          snackbar.enqueueSnackbar(
                            "Something went wrong: " + e,
                            {
                              variant: "error",
                            },
                          );
                        });
                    }}
                  >
                    Remove from space
                  </Button>
                )}
              </Stack>
            ) : (
              <Stack gap={2} direction="row">
                {featureExtractionEnabled &&
                  filterByLabel !== "suggested_asins" && (
                    <Button
                      variant="outlined"
                      sx={{
                        width: "140px",
                      }}
                      onClick={() => setIsDrawerOpen(true)}
                    >
                      Extract Features
                    </Button>
                  )}
                {filterByLabel !== "suggested_asins" && (
                  <Button
                    variant="outlined"
                    sx={{
                      width: "140px",
                    }}
                    onClick={() => setIsAddAsinsDrawerOpen(true)}
                  >
                    Add Products
                  </Button>
                )}
              </Stack>
            )}
          </Stack>
          {/*{(isLoading || isFetching) && <LinearProgress/>}*/}
          {/* <Divider sx={{ mb: "16px", pt: "16px" }} /> */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack>
                <InputLabel
                  htmlFor="color"
                  sx={{
                    marginBottom: "5px",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  Search
                </InputLabel>
                <TextField
                  variant="outlined"
                  placeholder="ASIN or Title"
                  value={filterText}
                  sx={{
                    "& .MuiInputBase-root": {
                      paddingLeft: "10px",
                      fontSize: "13px",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {filterText && (
                          <IconButton
                            onClick={() => setFilterText("")}
                            sx={{ padding: 0, background: "none" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <Box
                        sx={{
                          maxWidth: "145px",
                          width: contains ? "unset" : "100%",
                          marginRight: "10px",
                        }}
                      >
                        <Button
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          sx={{
                            minHeight: "unset",
                            padding: "2px 0.5rem",
                            justifyContent: "start",
                            background: theme.palette.grey[25],
                            color: theme.palette.grey[500],
                            marginRight: "5px",
                            width: "100%",
                            fontWeight: 500,
                            fontSize: "13px",
                            "& svg": {
                              transform: open ? "rotate(180deg)" : "unset",
                            },
                          }}
                          endIcon={<DropDownIcon />}
                        >
                          {contains ? "Contains" : "Does Not Contain"}
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={() => handleClose(contains)}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem onClick={() => handleClose(true)}>
                            Contains
                          </MenuItem>
                          <MenuItem onClick={() => handleClose(false)}>
                            Does Not Contain
                          </MenuItem>
                        </Menu>
                      </Box>
                    ),
                  }}
                  onChange={(e) => setFilterText(e.target.value)}
                />
              </Stack>
              <Stack>
                <InputLabel
                  htmlFor="color"
                  sx={{
                    marginBottom: "5px",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  Products
                </InputLabel>
                <ButtonGroup variant="outlined" aria-label="Basic button group">
                  <Button
                    variant={
                      filterByLabel === "all_product" ? "contained" : "outlined"
                    }
                    onClick={() => setFilterByLabel("all_product")}
                  >
                    All
                    {/* All products ({data?.metadata?.asins?.total}) */}
                  </Button>
                  <Button
                    variant={
                      filterByLabel === "my_product" ? "contained" : "outlined"
                    }
                    startIcon={<DarkStarIcon />}
                    onClick={() => setFilterByLabel("my_product")}
                    sx={{
                      "& path": {
                        fill:
                          filterByLabel === "my_product"
                            ? theme.palette.background.default
                            : theme.palette.secondary.main,
                      },
                    }}
                  >
                    Mine
                    {/* My products ({data?.metadata?.asins?.my_products}) */}
                  </Button>
                  <Button
                    variant={
                      filterByLabel === "competitive_product"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => setFilterByLabel("competitive_product")}
                  >
                    Competing
                    {/* Competitive products (
                  {data?.metadata?.asins?.competitive_products}) */}
                  </Button>
                  {/* {!!data?.metadata?.asins?.fetching_data && (
                  <Button
                    variant={
                      filterByLabel === "fetching_data"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => setFilterByLabel("fetching_data")}>
                    In Progress ({data?.metadata?.asins?.fetching_data})
                  </Button>
                )} */}
                  {!!data?.metadata?.suggested_asins?.total && (
                    <Button
                      variant={
                        filterByLabel === "suggested_asins"
                          ? "contained"
                          : "outlined"
                      }
                      startIcon={
                        <SVG
                          src={
                            filterByLabel === "suggested_asins"
                              ? "/sales/sparkle.svg"
                              : "/sales/sparkle_purple.svg"
                          }
                          fill="inherit"
                          width={20}
                          height={20}
                        />
                      }
                      onClick={() => setFilterByLabel("suggested_asins")}
                    >
                      Suggested
                      {/* Suggestion ({data?.metadata?.suggested_asins?.total}) */}
                    </Button>
                  )}
                </ButtonGroup>
                {/* <ButtonGroup variant="outlined" aria-label="Basic button group">

              </ButtonGroup> */}
              </Stack>
              <Stack
                height="100%"
                justifyContent="flex-end"
              // alignItems="flex-end"
              >
                <InputLabel
                  htmlFor="color"
                  sx={{
                    marginBottom: "5px",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  View
                </InputLabel>
                <ToggleButtonGroup
                  value={view}
                  exclusive
                  onChange={handleChangeLayout}
                  sx={{
                    height: 40,
                    "& .MuiToggleButton-root.Mui-selected": {
                      background: theme.palette.primary.main,
                      ":hover": {
                        background: theme.palette.primary.main,
                      },
                      "& svg": {
                        color: theme.palette.background.default,
                      },
                    },
                    "& svg": {
                      color: theme.palette.secondary.main,
                    },
                  }}
                >
                  <ToggleButton value="table" aria-label="table">
                    <FormatListBulletedOutlinedIcon />
                  </ToggleButton>
                  <ToggleButton value="grid" aria-label="grid">
                    <GridViewOutlinedIcon />
                  </ToggleButton>
                  {isPerfectContentV2Enabled && (
                    <ToggleButton value="perfect_content" aria-label="grid">
                      <PerfectContentIcon
                        fill={view === "perfect_content" ? "#fff" : "#000"}
                      />
                    </ToggleButton>
                  )}
                  {featureExtractionEnabled && (
                    <ToggleButton value="feature_extraction" aria-label="grid">
                      <CategoryIcon
                        fill={view === "feature_extraction" ? "#fff" : "#000"}
                      />
                    </ToggleButton>
                  )}
                </ToggleButtonGroup>
                {/* <ButtonGroup variant="outlined" aria-label="Basic button group">
                <Button
                  variant={
                    view === "table" ? "contained" : "outlined"
                  }
                  onClick={() => setView("table")}
                >
                  <FormatListBulletedOutlinedIcon />
                </Button>
                <Button
                  variant={
                    view === "grid" ? "contained" : "outlined"
                  }
                  onClick={() => setView("grid")}
                >
                  <GridViewOutlinedIcon />
                </Button>
              </ButtonGroup> */}
              </Stack>
              {/* {!!selectedProducts?.length && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                flex={1}>
                <span>{selectedProducts?.length} products selected</span>
                <Stack justifyContent="end" direction="row">
                  {filterByLabel === "suggested_asins" ? (
                    <>
                      <Button
                        onClick={() => {
                          moveSuggestedToSpaceMutation
                            .mutateAsync({
                              spaceId: spaceId!,
                              asins: selectedProducts,
                            })
                            .then(async () => {
                              snackbar.enqueueSnackbar(
                                selectedProducts?.length +
                                  " ASINs moved to space",
                                {
                                  variant: "success",
                                }
                              );
                              spaceDataRefetch();
                              await refatchAsins();
                              setSelectedProducts([]);
                            })
                            .catch((e) => {
                              snackbar.enqueueSnackbar(
                                "Something went wrong: " + e,
                                {
                                  variant: "error",
                                }
                              );
                            });
                        }}>
                        Add to space
                      </Button>
                      <Button
                        onClick={() => {
                          rejectSuggestedMutation
                            .mutateAsync({
                              spaceId: spaceId!,
                              asins: selectedProducts,
                            })
                            .then(async () => {
                              snackbar.enqueueSnackbar(
                                selectedProducts?.length +
                                  " ASINs removed from suggestion",
                                {
                                  variant: "success",
                                }
                              );
                              spaceDataRefetch();
                              await refatchAsins();
                              setSelectedProducts([]);
                            })
                            .catch((e) => {
                              snackbar.enqueueSnackbar(
                                "Something went wrong: " + e,
                                {
                                  variant: "error",
                                }
                              );
                            });
                        }}>
                        Remove from suggestion
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        removeASINsMutation
                          .mutateAsync({
                            spaceId: spaceId!,
                            asins: selectedProducts,
                          })
                          .then(async () => {
                            snackbar.enqueueSnackbar(
                              selectedProducts?.length + " ASINs removed",
                              {
                                variant: "success",
                              }
                            );
                            spaceDataRefetch();
                            await refatchAsins();
                            setSelectedProducts([]);
                          })
                          .catch((e) => {
                            snackbar.enqueueSnackbar(
                              "Something went wrong: " + e,
                              {
                                variant: "error",
                              }
                            );
                          });
                      }}>
                      Delete selected products
                    </Button>
                  )}
                </Stack>
              </Stack>
            )} */}
            </Stack>
            <Stack>
              <Button
                variant="text"
                onClick={handleKeywordsTable}
                startIcon={<TrendsIcon />}
                sx={{
                  marginTop: "1.5rem",
                  color: theme.palette.secondary.main,
                  padding: "0 1rem 0 1.3rem",
                  ":focus": {
                    background: "unset",
                  },
                  ...(openKeywordsTable && {
                    color: theme.palette.text.link,
                    background: theme.palette.primary.light,
                    ":focus": {
                      background: theme.palette.primary.light,
                    },
                    "& svg > path": {
                      stroke: theme.palette.text.link,
                    },
                  }),
                }}
              >
                Keywords
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack height="100%" overflow="auto">
          <Stack
            height="100%"
            sx={{
              flexGrow: 1,
              transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              marginRight: 0,
              ...(openKeywordsTable && {
                transition: theme.transitions.create("margin", {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
                }),
                marginRight: `calc(40% + 6rem - ${navbarMode === NavbarModes.COLLAPSED ? "66px" : "0px"
                  })`,
              }),
              position: "relative",
            }}
          >
            {view === "grid" && (
              <AsinsList
                spaceId={spaceId as string}
                productCountLabel={productCountLabel}
                asins={asins}
                undoAction={undoAction}
                allSpaceAsins={allSpaceAsins}
              />
            )}
            {view === "table" && (
              <AsinsListTableView
                spaceId={spaceId as string}
                productCountLabel={productCountLabel}
                asins={asins}
                gridApi={gridApi}
                setGridApi={setGridApi}
                // asinsFromGrid={asinsFromGrid}
                createDataSource={createDataSource}
                undoAction={undoAction}
                gridRef={gridRef}
                onModelUpdated={onModelUpdated}
                allSpaceAsins={allSpaceAsins}
              />
            )}
            {view === "perfect_content" && (
              <AsinsListPerfectContentView spaceId={spaceId as string} />
            )}
            {view === "feature_extraction" && (
              <AsinsListFeatureExtractionView spaceId={spaceId as string} />
            )}
          </Stack>
          <Stack>
            <KeywordsTable open={openKeywordsTable} spaceId={spaceId} />
          </Stack>
        </Stack>
      </Stack>
      <Drawer anchor="right" open={isDrawerOpen}>
        <ClickAwayListener
          onClickAway={() => {
            setIsDrawerOpen(false);
          }}
        >
          <Box
            sx={{
              height: "100vh",
            }}
          >
            <ExtractFeaturesDrawer setIsDrawerOpen={setIsDrawerOpen} />
          </Box>
        </ClickAwayListener>
      </Drawer>
      <Drawer anchor="right" open={isAddAsinsDrawerOpen}>
        <ClickAwayListener
          onClickAway={() => {
            setIsAddAsinsDrawerOpen(false);
          }}
        >
          <Box
            sx={{
              height: "100vh",
            }}
          >
            <AddManualSpaceDrawer
              spaceId={spaceId as string}
              setIsDrawerOpen={setIsAddAsinsDrawerOpen}
            />
          </Box>
        </ClickAwayListener>
      </Drawer>
    </>
  );
};

export default SpaceDetail;
