import { useHistory } from "react-router";

import SVG from "react-inlinesvg";
import { LeftNavBarItem } from "./LeftNavBarItem";
import { Dispatch, FC, SetStateAction } from "react";
import { useSplitEnabled } from "../../../hooks";
import { Split } from "../../../FeatureFlags/enums";

export const SalesInsightsNavItem: FC<{
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ tab, setTab }) => {
  const history = useHistory();
  const isGroup = useSplitEnabled(Split.SALES_ASSISTANT_GROUPS);

  // const label = true ? "Sales Feed" : "Sales Assistant"
  const label = "Sales Feed";

  return (
    <LeftNavBarItem
      selected={tab === "sales-assistant"}
      icon={<SVG src={"/sales/insights-new.svg"} width={20} height={20} />}
      // onClick={() => history.push(url)}
      onClick={() => {
        history.push(
          isGroup ? "/sales-assistant/home" : "/sales-assistant/products"
        );
        setTab("sales-assistant");
      }}
      label={label}
    />
  );
};
