import { Box, Stack, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import themev2 from "../../themev2";
import ApplicationsLeftNav from "./LeftNavbar";
import ApplicationsLeftNavV2 from "./LeftNavbarV2";
import Router from "./Router";
import { FC, useMemo, useState } from "react";
import { useAuthUser } from "@frontegg/react";
import { useUserTenant } from "../../hooks";
import { useTenantConfiguration } from "../../services/tenants/useTenantConfiguration.ts";

const Applications: FC<object> = () => {
  const [isDrawerPinned, setIsDrawerPinned] = useState<boolean>(false);

  const user = useAuthUser();
  const tenant = useUserTenant(user || undefined);
  const { data: tenantConfig } = useTenantConfiguration(
    tenant?.tenantId || "",
    { enabled: !!tenant?.tenantId },
  );
  const isLeftNavV2 = useMemo(() => {
    return tenantConfig?.configuration?.use_new_menu_bar;
  }, [tenantConfig]);

  const [tab, setTab] = useState("");

  return (
    <ThemeProvider theme={themev2}>
      <CssBaseline />
      <Stack direction="row" height="100%">
        {isLeftNavV2 ? (
          <ApplicationsLeftNavV2
            isDrawerPinned={isDrawerPinned}
            setIsDrawerPinned={setIsDrawerPinned}
            tab={tab}
            setTab={setTab}
          />
        ) : (
          <ApplicationsLeftNav />
        )}
        <Box height="100vh" width="100%" sx={{ transition: "0.3s all ease" }}>
          <Router />
        </Box>
      </Stack>
    </ThemeProvider>
  );
};

export default Applications;
