import { Box } from "@mui/material";

const LoadingSpinner = (props: any) => {
  const { size, color, borderWidth } = props;

  return (
    <Box
      onClick={() => {}}
      sx={{
        "& .lds-dual-ring": {
          marginLeft: "auto",
          marginRight: "auto",
          width: size ? `${size}px` : "15px",
          height: size ? `${size}px` : "15px",
        },
        "& .lds-dual-ring:after": {
          content: `" "`,
          display: "block",
          width: size ? `${size}px` : "15px",
          height: size ? `${size}px` : "15px",
          borderRadius: "50%",
          border: `${borderWidth || "1.5"}px solid ${color || "#fff"}`,
          borderColor: `${color || "#fff"} transparent ${color || "#fff"} transparent`,
          animation: "lds-dual-ring 1.2s linear infinite",
        },
        "@keyframes lds-dual-ring": {
          "0%": {
            transform: "rotate(0deg)",
          },
          "100%": {
            transform: "rotate(360deg)",
          },
        },
      }}
    >
      <div className="lds-dual-ring"></div>
    </Box>
  );
};

export default LoadingSpinner;
