import { useForm } from "react-hook-form";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { Features } from "./types.ts";
import { useSnackbar } from "notistack";

const sampleJsonString = `[\n  {\n    "attribute": "attribute1",\n    "description": "description1",\n    "accepted_values": ["value1", "value2"],\n    "closed_list": true,\n    "type": "string"\n  },\n  {\n    "attribute": "attribute2",\n    "description": "description2",\n    "accepted_values": ["value1", "value2"],\n    "closed_list": true,\n    "type": "string"\n  }\n]`;
const AddAttributeFormJson = ({
  add,
  setExpanded,
}: {
  add: (attribute: Features) => void;
  setExpanded: (expanded: boolean) => void;
}) => {
  const snackbar = useSnackbar();

  const { formState, register, handleSubmit } = useForm({
    defaultValues: {
      json: "",
    },
  });

  const isJsonString = (str: string) => {
    try {
      return Array.isArray(JSON.parse(str));
    } catch (e) {
      return false;
    }
  };

  const validateFeaturesJson = (json: any[]) => {
    if (!json.length) {
      throw new Error("Empty JSON");
    }
    json.every((attribute) => {
      if (!attribute.attribute) {
        throw new Error("Attribute is missing");
      }
      if (!attribute.description) {
        throw new Error("Description is missing");
      }
      if (!attribute.type) {
        throw new Error("Type is missing");
      }
      if (!attribute.accepted_values && attribute.closed_list) {
        throw new Error("Accepted values are missing for closed list");
      }
      if (attribute.accepted_values && !attribute.closed_list) {
        throw new Error("Accepted values should be empty for open list");
      }
      if (
        attribute.accepted_values &&
        !Array.isArray(attribute.accepted_values)
      ) {
        throw new Error("Accepted values should be an array");
      }
      if (attribute.accepted_values) {
        const validationType =
          attribute.type === "int" || attribute.type === "float"
            ? "number"
            : attribute.type;
        attribute.accepted_values.forEach((value: any) => {
          if (typeof value !== validationType) {
            throw new Error("Accepted values type mismatch");
          }
        });
      }
    });

    return true;
  };

  return (
    <Stack spacing={1}>
      <TextField
        placeholder={sampleJsonString}
        multiline
        {...register(`json`, { required: true, validate: isJsonString })}
        rows={16}
        error={!formState.isValid}
      />
      {!formState.isValid && (
        <Typography color="error">Invalid JSON</Typography>
      )}
      <Button
        variant="contained"
        type="button"
        onClick={handleSubmit((data) => {
          try {
            const parsedJson = JSON.parse(data.json);
            validateFeaturesJson(parsedJson) &&
              parsedJson.forEach((attribute: any) =>
                add(attribute as Features)
              );
          } catch (e) {
            snackbar.enqueueSnackbar(`${e}`, {
              variant: "error",
            });
          }
          setExpanded(false);
        })}
        disabled={!formState.isValid}
      >
        Add features
      </Button>
    </Stack>
  );
};

export default AddAttributeFormJson;
