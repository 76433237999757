import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { TrendingUp } from "@mui/icons-material";
import { sortBy } from "lodash";
import { CircuitBoard } from "../../../types";
import { useBoards } from "../../../services/boards";
import { ExpendableList, ExpendableListHeader } from "./ExpendableList";
import { capitaliseFirst } from "../../../utils";
import { ApplicationType } from "./types";
import { BoardsMenu } from "./BoardsMenu";
import { StyledTooltip } from "./StyledTooltip";
import { Dispatch, FC, SetStateAction, useState } from "react";
import theme from "../../../themes/light";

export const TrendspottingList: FC<{
  open: boolean;
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ open, tab, setTab }) => {
  const history = useHistory();
  const [openList, setOpenList] = useState(false);
  // const pageMatch = useRouteMatch(
  //   ["", ApplicationType.TRENDSPOTTING].join("/")
  // );
  const match = useRouteMatch<{ boardId: string; page: string }>(
    ["", ApplicationType.TRENDSPOTTING, ":boardId"].join("/")
  );

  const handleTrendSpotting = () => {
    setOpenList(!openList);
    setTab("trendSpotting");
  };
  const { data: blocks = [] as CircuitBoard[], isLoading } = useBoards(
    undefined,
    {
      select: (blocks) =>
        sortBy(
          blocks.filter(
            (b) => b.template_id === "breakout-trends" && b.published_run_id
          ),
          "displayName"
        ),
    }
  );
  if (isLoading) {
    return (
      <ListItemButton>
        <Skeleton height="100%" width="100%" />
      </ListItemButton>
    );
  }

  if (!blocks.length) {
    return null;
  }

  if (!open) {
    return (
      <StyledTooltip
        title={
          <BoardsMenu blocks={blocks} prefix={ApplicationType.TRENDSPOTTING} />
        }
      >
        <ListItemButton
          onClick={() => {
            history.push(["", ApplicationType.TRENDSPOTTING].join("/"));
            setTab("trendSpotting");
          }}
          selected={tab === "trendSpotting"}
        >
          <ListItemIcon>
            <TrendingUp sx={{ color: theme.palette.backgrounds.white }} />
          </ListItemIcon>
          <ListItemText
            primary={capitaliseFirst(ApplicationType.TRENDSPOTTING)}
          />
        </ListItemButton>
      </StyledTooltip>
    );
  }

  return (
    <ExpendableList
      open={tab === "trendSpotting"}
      label={(props) => (
        <ExpendableListHeader
          icon={<TrendingUp sx={{ color: theme.palette.backgrounds.white }} />}
          onClick={handleTrendSpotting}
          label={capitaliseFirst(ApplicationType.TRENDSPOTTING)}
          {...props}
        />
      )}
    >
      {blocks.map((b, i) => (
        <ListItemButton
          key={i}
          onClick={() => {
            setTab("trendSpotting");
            history.push(
              ["", ApplicationType.TRENDSPOTTING, b.identifier].join("/")
            );
          }}
          selected={b.identifier === match?.params.boardId}
        >
          {b.displayName}
        </ListItemButton>
      ))}
    </ExpendableList>
  );
};
