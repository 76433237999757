import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { NavbarModes } from "./enums";
import { useSearchParams, useUserTenant } from "./hooks";
import { useAuthUser } from "@frontegg/react";
import { useTenantConfiguration } from "./services/tenants/useTenantConfiguration.ts";

export type AppState = {
  applicationMode: boolean;
  navbarMode: NavbarModes;
  insightType: string;
};
export type AppContextType = AppState & {
  setApplicationMode: (isSet: boolean) => void;
  setNavbarMode: (mode: NavbarModes) => void;
  setInsightType: (isSet: string) => void;
};

export enum TYPES {
  "APPLICATIONS_MODE" = "applications-mode",
  NAVBAR_MODE = "navbar-mode",
  INSIGHT_MODE = "",
}

const isAppModeByDefault = true;
const localAppMode: string | null = localStorage.getItem(
  TYPES.APPLICATIONS_MODE,
);

const navbarModeDefault = NavbarModes.EXPENDED;
const localNavbarMode: string | null = localStorage.getItem(TYPES.NAVBAR_MODE);

export const initialValue: AppState & AppContextType = {
  applicationMode:
    localAppMode !== null ? localAppMode !== "false" : isAppModeByDefault,
  navbarMode:
    localNavbarMode !== null
      ? (localNavbarMode as NavbarModes)
      : navbarModeDefault,
  // navbarMode: navbarModeDefault,
  insightType: "",
  setApplicationMode: () => {},
  setNavbarMode: () => {},
  setInsightType: () => {},
};
export const AppContext = createContext<AppContextType>(initialValue);

export const reducer = (state: AppState, action: any) => {
  switch (action.type) {
    case TYPES.APPLICATIONS_MODE:
      return { ...state, applicationMode: action.payload };
    case TYPES.NAVBAR_MODE:
      return { ...state, navbarMode: action.payload };
    case TYPES.INSIGHT_MODE:
      return { ...state, insightType: action.payload };
    default:
      throw new Error("Wrong context reducer type ");
  }
};

export const ApplicationContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { search } = useSearchParams();
  const user = useAuthUser();
  const tenant = useUserTenant(user || undefined);
  const { data: tenantConfig } = useTenantConfiguration(
    tenant?.tenantId || "",
    { enabled: !!tenant?.tenantId },
  );
  const isLeftNavV2 = useMemo(() => {
    return tenantConfig?.configuration?.use_new_menu_bar;
  }, [tenantConfig]);

  const newNavbarModeDefault = isLeftNavV2
    ? NavbarModes.COLLAPSED
    : NavbarModes.EXPENDED;

  const newInitialValue = {
    ...initialValue,
    navbarMode:
      localNavbarMode !== null && !isLeftNavV2
        ? (localNavbarMode as NavbarModes)
        : newNavbarModeDefault,
  };

  const [state, dispatch] = useReducer(reducer, {
    ...newInitialValue,
    applicationMode: newInitialValue.applicationMode && search?.lab == null,
  });
  const setApplicationMode = (isSet: boolean) =>
    dispatch({ type: TYPES.APPLICATIONS_MODE, payload: isSet });

  const setNavbarMode = (mode: NavbarModes) => {
    dispatch({ type: TYPES.NAVBAR_MODE, payload: mode });
  };

  const setInsightType = (type: string) => {
    dispatch({ type: TYPES.INSIGHT_MODE, payload: type });
  };

  useEffect(() => {
    localStorage.setItem(
      TYPES.APPLICATIONS_MODE,
      JSON.stringify(state.applicationMode),
    );
  }, [state.applicationMode]);

  useEffect(() => {
    localStorage.setItem(TYPES.NAVBAR_MODE, state.navbarMode);
  }, [state.navbarMode]);

  return (
    <AppContext.Provider
      value={{
        ...state,
        setApplicationMode,
        setNavbarMode,
        setInsightType,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
