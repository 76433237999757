import {
  Box,
  Card,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import NumberSection from "../Components/NumberSection.tsx";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import { InfoIconV2 } from "../../../assets/index.tsx";
import { renderToString } from "react-dom/server";
import { convertNumberToShortFormat } from "../../ResearchAssistant/utils.ts";
import "./CustomTooltip.css";

const CustomTooltip = ({ series, dataPointIndex }: any) => (
  <div className="tooltip-container">
    {series.map((serie: any, index: number) => {
      return (
        <span key={index} className={`tooltip-value`}>
          {serie[dataPointIndex]}
        </span>
      );
    })}
    <div className="tooltip-arrow"></div>
  </div>
);

const CardSkeleton = () => (
  <Box
    sx={{
      height: "110px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 12px",
      boxShadow:
        "0px 12px 45px rgba(44, 34, 76, 0.05), 0px 8px 20px rgba(44, 34, 76, 0.05)",
    }}
  >
    <Box
      sx={{
        width: "30%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {Array.from({ length: 3 }).map((_, index) => (
        <Skeleton
          key={`skeleton-card-${index}`}
          variant="text"
          sx={{
            borderRadius: "8px",
            height: "15px",
            width: index === 2 ? "50%" : "75%",
            transform: "unset",
          }}
        />
      ))}
    </Box>
    <Skeleton
      variant="rectangular"
      sx={{
        height: "100%",
        width: "70%",
        borderRadius: "8px",
      }}
    />
  </Box>
);

const CardPlaceholder = () => (
  <Box
    sx={{
      height: "110px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 12px",
      boxShadow:
        "0px 12px 45px rgba(44, 34, 76, 0.05), 0px 8px 20px rgba(44, 34, 76, 0.05)",
    }}
  >
    <Box
      sx={{
        width: "30%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Typography
        sx={{
          borderRadius: "8px",
          height: "15px",
          width: "75%",
          transform: "unset",
        }}
      >
        No results
      </Typography>
    </Box>
    <Box
      sx={{
        height: "100%",
        width: "70%",
        borderRadius: "8px",
      }}
    />
  </Box>
);
const Index = () => {
  const { salesAssistantHeaderData, salesAssistantHeaderDataLoading } =
    useSalesAssistant();

  const chartColors = ["#6A3FB2", "#0062CC", "#1AD075", "#F89A43"];
  return (
    <Grid container spacing={2} marginBottom="10px">
      {!salesAssistantHeaderDataLoading && salesAssistantHeaderData
        ? salesAssistantHeaderData?.map((card, index) => {
            const isCardPercentage = ["ACOS"].includes(card.title);
            return (
              <Grid item xs={3} key={`sales_assistant_head_card-${index}`}>
                <Card
                  sx={{
                    flex: 1,
                    height: "110px",
                    filter:
                      "drop-shadow(0px 12px 45px rgba(44, 34, 76, 0.05)) drop-shadow(0px 8px 20px rgba(44, 34, 76, 0.05))",
                    border: "0 none",
                    borderRadius: "8px",
                    padding: "5px 12px",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                        width: "fit-content",
                        position: "absolute",
                        zIndex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          textWrap: "nowrap",
                          fontSize: "16px",
                          fontWeight: 600,
                          lineHeight: "24px",
                        }}
                      >
                        {card.title}
                      </Typography>
                      <Tooltip
                        title="Text"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              zIndex: 2,
                            },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "inline-flex",
                            cursor: "pointer",
                          }}
                        >
                          <InfoIconV2 />
                        </Box>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "35px",
                      }}
                    >
                      {!isCardPercentage ? (
                        <NumberSection
                          number={card.value}
                          change={card.change}
                          unit="$"
                          vertical
                        />
                      ) : (
                        <NumberSection
                          number={card.value}
                          percentage={card.change}
                          unit="%"
                          vertical
                        />
                      )}
                    </Box>

                    {card.graph_data && card.graph_data.length > 0 ? (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "-25%",
                          right: "-5%",
                          width: "75%",
                        }}
                      >
                        <Chart
                          height="140px"
                          id="sa-chart"
                          options={{
                            chart: {
                              id: card.title,
                              toolbar: {
                                show: false,
                                tools: {
                                  download: false,
                                },
                              },
                            },
                            grid: {
                              xaxis: {
                                lines: {
                                  show: false,
                                },
                              },
                              yaxis: {
                                lines: {
                                  show: false,
                                },
                              },
                            },
                            stroke: {
                              width: 2,
                              curve: "smooth",
                              colors: [
                                ...chartColors.slice(index),
                                ...chartColors.slice(0, index),
                              ],
                            },
                            tooltip: {
                              enabled: true,
                              shared: true,
                              intersect: false,
                              followCursor: true,
                              custom: ({ series, dataPointIndex }: any) => {
                                return renderToString(
                                  <CustomTooltip
                                    series={series.map((serie: any) =>
                                      serie.map((value: any) =>
                                        convertNumberToShortFormat(
                                          value,
                                          isCardPercentage
                                        )
                                      )
                                    )}
                                    dataPointIndex={dataPointIndex}
                                  />
                                );
                              },
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            fill: {
                              colors: [
                                ...chartColors.slice(index),
                                ...chartColors.slice(0, index),
                              ],
                              type: "gradient",
                              gradient: {
                                shade: "light",
                                type: "vertical",
                                opacityFrom: 0.5,
                                opacityTo: 0,
                              },
                            },
                            xaxis: {
                              tickAmount: 3,
                              categories: card.categories,
                              labels: {
                                formatter: function (value) {
                                  return dayjs(parseInt(value) / 1000).format(
                                    "MMM"
                                  );
                                },
                                rotate: 0,
                                style: {
                                  colors: "#75717B",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                },
                              },
                              axisTicks: {
                                show: false,
                              },
                            },
                            yaxis: {
                              labels: {
                                show: false,
                              },
                            },
                          }}
                          series={[
                            {
                              name: card.title,
                              data: card.graph_data,
                            },
                          ]}
                          type="area"
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#75717B",
                          }}
                        >
                          No data available
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Card>
              </Grid>
            );
          })
        : Array.from({ length: 4 }).map((_, index) => (
            <Grid item xs={3} key={`sales_assistant_head_card-${index}`}>
              {salesAssistantHeaderDataLoading ? (
                <CardSkeleton />
              ) : (
                <CardPlaceholder />
              )}
            </Grid>
          ))}
    </Grid>
  );
};

export default Index;
