import { Stack } from "@mui/material";
import theme from "../../../themes/light.ts";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import List from "./List.tsx";
import ListHead from "./ListHead.tsx";

const Index = () => {
  const {
    salesAssistantList,
    selectedGroupBy,
    spaceId,
    brand,
  } = useSalesAssistant();

  const { data, total } = salesAssistantList ?? {};

  const variant = !!spaceId || !!brand ? "asins" : selectedGroupBy;

  return (
    <Stack
      sx={{
        overflow: "hidden",
        background: theme.palette.backgrounds.secondary,
        padding: "12px 40px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        gap: "14px",
        width: "100%",
      }}
    >
      <ListHead total={total} variant={variant} />
      <List data={data} total={total} variant={variant} />
    </Stack>
  );
};

export default Index;
