import theme from "../../themes/light.ts";
import { Box, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import CustomPagination from "./Pagination";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { EcommerceProduct } from "../../types/ecommerce.ts";
import EComProduct from "./EComProduct.tsx";
import { getAllEcommerceProducts, useEComProductsQuery } from "./useSpaces.ts";
import { EComRequest } from "./types.ts";
import EmptyState from "./EmptyState.tsx";
import { GetProductsResponse } from "../../services/ecommerce/useEcommerceProducts.ts";
import { Checkbox } from "@mui/material";
import { CheckboxFilledIcon } from "../../assets/index.tsx";
import { Skeleton } from "@mui/material";
import { ProductData } from "./CatalogView.tsx";

type CatalogViewGridProps = {
  selectedProducts: string[];
  setSelectedProducts: Dispatch<SetStateAction<string[]>>;
  search: string;
  selectedBrands: string[];
  selectedCategories: string[][];
  asinsFromGrid: ProductData;
  setAsinsFromGrid: (data: ProductData) => void;
};

const CatalogViewGrid = ({
  selectedProducts,
  setSelectedProducts,
  search,
  selectedBrands,
  selectedCategories,
  setAsinsFromGrid,
  asinsFromGrid,
}: CatalogViewGridProps) => {
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [eComRequest, setEComRequest] = useState<EComRequest>({
    offset: 0,
    limit: 100,
  });
  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const filter: any = {};
    if (search) {
      const searchText = search.replaceAll(/\s/g, "");
      if (searchText?.includes(",")) {
        const titles = searchText?.split(",");
        filter.titles = [...titles];
      } else {
        filter.titles = [search];
      }
    }
    if (selectedBrands) {
      filter.brands = selectedBrands;
    }
    if (selectedCategories) {
      const formattedCategory = selectedCategories.map((categoryArray) =>
        categoryArray.join("|")
      );
      filter.categories = formattedCategory;
    }
    setEComRequest((prev) => ({
      ...prev,
      filter,
    }));
  }, [search, selectedBrands, selectedCategories]);

  useEffect(() => {
    setEComRequest((prev) => ({
      ...prev,
      offset: (currentPage - 1) * 100,
    }));
  }, [currentPage, setEComRequest]);

  const { data, isLoading } = useEComProductsQuery(eComRequest);

  const setProductsLength = useCallback(
    (res: GetProductsResponse, search?: string) => {
      if (
        asinsFromGrid?.asins?.length !== selectedProducts?.length ||
        asinsFromGrid?.asins?.length === 0
      ) {
        const asins = res?.items?.map((product) => product?.asin);
        setAsinsFromGrid({
          asins,
          total: res?.total,
          isDataReady: !search || false,
        });
      }
    },
    []
  );

  useEffect(() => {
    if (data?.total) setTotalRows(data?.total as number);
    if (data) {
      setProductsLength(data);
    }
  }, [data?.total]);

  const handleSelectAll = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, data: any) => {
      if (event.target.checked) {
        const filter: any = {};
        if (search) {
          const searchText = search.replaceAll(/\s/g, "");
          if (searchText?.includes(",")) {
            const titles = searchText?.split(",");
            filter.titles = [...titles];
          } else {
            filter.titles = [search];
          }
        }
        if (selectedBrands) {
          filter.brands = selectedBrands;
        }
        if (selectedCategories) {
          const formattedCategory = selectedCategories.map((categoryArray) =>
            categoryArray.join("|")
          );
          filter.categories = formattedCategory;
        }
        if(data?.total < 100) {
          const asinList = data?.items?.map((product: any) => product?.asin)
          setSelectedProducts(asinList);
        } else {
          const { asins } = await getAllEcommerceProducts(filter);
          setSelectedProducts(asins);
        }
        return;
      }
      setSelectedProducts([]);
    },
    [search, selectedBrands, selectedCategories, setSelectedProducts]
  );

  if (data && (data as GetProductsResponse).items.length === 0) {
    return <EmptyState />;
  }
  return (
    <Box
      sx={{
        flex: 1,
        background: theme.palette.grey[50],
        overflow: "auto",
        "::-webkit-scrollbar": {
          display: "none",
        },
        position: "relative",
        width: "100%",
        ".custom-pagination": {
          position: "fixed",
          bottom: "60px",
          width: "100%",
          alignSelf: "center",
        },
      }}
    >
      {isLoading && <LinearProgress />}
      <Stack>
        <Stack>
          {isLoading ? (
            <Skeleton
              animation="wave"
              sx={{
                width: "160px",
                height: "16px",
                borderRadius: "8px",
                transform: "unset",
                margin: "24px 0 0",
              }}
            />
          ) : (
            <Stack
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "6px",
              }}
            >
              <Checkbox
                checked={
                  selectedProducts.length === 0
                    ? false
                    : data?.total === selectedProducts?.length
                }
                onChange={(e) => handleSelectAll(e, data)}
                indeterminateIcon={<CheckboxFilledIcon />}
                indeterminate={
                  selectedProducts?.length > 0 &&
                  data?.total !== selectedProducts?.length
                }
                sx={{ padding: "6px 0" }}
              />
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 500,
                  paddingLeft: "12px",
                }}
              >
                Select All
              </Typography>
            </Stack>
          )}
        </Stack>
        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="stretch"
          sx={{
            flex: 1,
            background: theme.palette.grey[50],
            marginRight: "16px",
            width: "auto",
            paddingBottom: "64px",
            marginTop: 0,
          }}
          spacing={2}
        >
          {data?.items?.map((product: EcommerceProduct) => (
            <EComProduct
              product={product}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
          ))}
        </Grid>
        <CustomPagination
          totalRows={totalRows}
          currentPage={currentPage}
          handleChange={handlePageChange}
          type="ProductList"
        />
      </Stack>
    </Box>
  );
};

export default CatalogViewGrid;
